var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"loading-text":"Laded... Bitte warten!"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"grey lighten-5",attrs:{"flat":""}},[(_vm.showTitle)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.showTitle)?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),(_vm.hasSlot('toolbar'))?[_c('v-row',{attrs:{"no-gutter":""}},[(_vm.hasSlot('toolbar'))?_c('v-col',[_vm._t("toolbar")],2):_vm._e()],1)]:[_c('v-text-field',{attrs:{"label":"Suche","hide-details":"","single-line":"","clearable":"","append-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.showButtonBar)?[_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.showAddButton)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"primary","dark":"","disabled":_vm.disableAdd},on:{"click":_vm.onAddItem}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("add")]),_vm._v("Neu ")],1)]}}],null,false,2329006435)},[_c('span',[_vm._v("Neuer Eintrag")])]):_vm._e()]:_vm._e()]],2)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [(_vm.filter(item))?_c('tr',_vm._l((_vm.headers),function(key){return _c('td',{key:key.value},[(key.type == null || key.type === 'text')?[_c('span',[_vm._v(_vm._s(_vm.displayDefault(key.value, item)))])]:_vm._e(),(key.type === 'checkbox')?[_c('v-simple-checkbox',{attrs:{"disabled":"","value":item[key.value],"ripple":false}})]:_vm._e(),(key.type === 'datetime')?[_c('span',[_vm._v(_vm._s(_vm.displayDatetime(item[key.value])))])]:_vm._e(),(key.type === 'actions')?[_c('v-row',[(
                _vm.showEditButton &&
                  item.id &&
                  item.statusdescription != 'eingeladen'
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"primary","x-small":"","fab":"","disabled":_vm.disableEdit},on:{"click":function($event){return _vm.onEditItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Datensatz bearbeiten")])]):_vm._e(),(_vm.showDeleteButton)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"error","x-small":"","fab":"","disabled":_vm.disableDelete},on:{"click":function($event){return _vm.onDeleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Datensatz löschen")])]):_vm._e(),_vm._t("actions",null,null,{ item: item })],2)]:_vm._e()],2)}),0):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }