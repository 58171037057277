console.log(`Settings Build Version: ${process.env.VUE_APP_VERSION}`);
console.log(`Settings Environment: ${process.env.VUE_APP_ENVIRONMENT}`);

const dev_port = '6055';
const dev_api = 'http://' + location.hostname + ':' + dev_port + '/api/v1/';
console.log(`DevApi: ${dev_api}`);

if (process.env.VUE_APP_ENVIRONMENT == "development") {
    // dev local
    var apienvironment = "local";
    var apihostname = 'http://' + location.hostname + ':' + dev_port;
    var apidebugging = "XDEBUG_SESSION_START=ECLIPSE";
    var api_errormessage = true;
    console.log('I am looking for api on: ' + dev_api);
} else {
    // production or staging docker
    apienvironment = "docker";
    apihostname = 'https://' + location.hostname;
    apidebugging = "";
    api_errormessage = false;
    console.log('I am looking for api on: ' + location.hostname + '/api/v1/');
}

export default {
    apienvironment,
    apihostname,
    apidebugging,
    api_errormessage
  };


