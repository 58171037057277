var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('Box',[_c('div',{attrs:{"id":"logo_home"}})]),_c('Box',{attrs:{"title":("Herzlich willkommen, " + (_vm.$store.userData.title != null ? ((_vm.$store.userData.title) + " ") : '') + (_vm.$store.userData.firstname) + " " + (_vm.$store.userData.lastname) + "!")}},[_c('p',[_vm._v(" Der TeamCheck ist ein Tool zur Analyse der gemeinsamen Vorstellungen zu Schlüsselelementen und –prozessen im Team (sog. Teamkognitionen). Die Ergebnisse helfen dabei, Unklarheiten und Informationsbedarfe aufzudecken. Damit unterstützt Sie TeamCheck bei der gemeinsamen und kontinuierlichen Reflexion, die letztendlich Ihre Teamarbeit effektiver macht. ")]),_c('p',[_vm._v(" Starten Sie Ihren TeamCheck und erstellen Sie für Ihr Team eine eigene Umfrage – schnell und unkompliziert. Klicken Sie dafür im Menü links auf „Teams“, um Ihr Team anzulegen, und lassen Sie sich in wenigen Schritten durch die Erstellung einer Umfrage führen. ")]),_c('p',[_vm._v(" Viel Spaß und Erfolg dabei! ")])]),_c('InvitesTable',{attrs:{"reload":_vm.reloadInvitesTable},on:{"update:reload":function($event){_vm.reloadInvitesTable=$event},"action":function (e) {
          if (e) { this$1.reload = true; }
        }}}),_c('Box',{attrs:{"title":"Offene Umfragen"}},[_c('p',[_vm._v(" Hier sehen Sie die aktuellen Umfragen, die für Sie zur Beantwortung offen sind. ")]),_c('EditTable',{attrs:{"reload":_vm.reloadSurveys,"headers":_vm.tableHeadersSurvey,"api_load":"surveys/ListMySurveys/0/0/2"},on:{"update:reload":function($event){_vm.reloadSurveys=$event}},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","fab":"","disabled":!item.IsStartable4User && !item.IsActive4User},on:{"click":function($event){return _vm.startSurvey(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.IsStartable4User ? "assignment" : item.IsActive4User ? "pending_actions" : "assignment_turned_in"))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.IsActive4User ? "Teilnahme fortsetzen" : "teilnehmen"))])])]}}])}),_c('SurveyAnswerDialog',{attrs:{"survey":_vm.activeSurvey},model:{value:(_vm.showSurveyAnswerDialog),callback:function ($$v) {_vm.showSurveyAnswerDialog=$$v},expression:"showSurveyAnswerDialog"}})],1),_c('Box',{attrs:{"title":"Wissenswertes"}},[_c('p',[_vm._v(" Sie möchten Ihr Wissen rund um die im TeamCheck erhobenen Teamkognitionen und Teamprozesse noch weiter vertiefen? ")]),_c('p',[_vm._v(" Viel Spaß beim Stöbern! ")]),_vm._l((_vm.knowledgeButtons),function(item,i){return _c('v-btn',{key:i,staticClass:"ma-2 text-subtitle-2 text-capitalize",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.viewPdf(item.text, item.pdf)}}},[_vm._v(_vm._s(item.text))])})],2),_c('Box',{attrs:{"title":"Tipps & Tricks"}},[_c('p',[_vm._v(" Sie suchen nach Tipps & Tricks, wie Sie Ihre digitale Zusammenarbeit in der Praxis stärken können? ")]),_c('p',[_vm._v(" Hier haben wir einiges für Sie zusammengestellt. ")]),_vm._l((_vm.hintButtons),function(item,i){return _c('v-btn',{key:i,staticClass:"ma-2 text-subtitle-2 text-capitalize",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.viewPdf(item.text, item.pdf)}}},[_vm._v(_vm._s(item.text))])})],2),_c('Box',[_c('div',{attrs:{"id":"logo_sponsor"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }