var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('EditTable',{attrs:{"reload":_vm.reload,"title":"Mitarbeiter","headers":_vm.cols,"api_load":"company/GetCompanyUsers","createfields":_vm.create,"api_create":"users/createuser","editfields":_vm.edit,"api_edit":"users/SetUserData","deletefield":"email","api_delete":"users/DeleteUser","deleteif":function (item) { return item.email != _vm.$store.userData.email; }},on:{"update:reload":function($event){_vm.reload=$event}},scopedSlots:_vm._u([{key:"default",fn:function(item){return [(item.isCompanyAdmin == 0 && item.email != _vm.$store.userData.email)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","fab":""},on:{"click":function($event){return _vm.companyAdmin(item, 1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("star")])],1)]}}],null,true)},[_c('span',[_vm._v("Admin Berechtigung umschalten")])]):_vm._e(),(item.isCompanyAdmin == 1 && item.email != _vm.$store.userData.email)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","small":"","fab":""},on:{"click":function($event){return _vm.companyAdmin(item, 0)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("star_outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Admin Berechtigung umschalten")])]):_vm._e(),(item.isLocked == 0 && item.email != _vm.$store.userData.email)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","fab":""},on:{"click":function($event){return _vm.lock(item, 1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("lock")])],1)]}}],null,true)},[_c('span',[_vm._v("sperren")])]):_vm._e(),(item.isLocked == 1 && item.email != _vm.$store.userData.email)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","small":"","fab":""},on:{"click":function($event){return _vm.lock(item, 0)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("lock_open")])],1)]}}],null,true)},[_c('span',[_vm._v("entsperren")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }