var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-dialog',{attrs:{"width":"80%","title":"Team bearbeiten","subtitle":"","onConfirm":_vm.$_save,"confirmStyle":{
    text: 'Team speichern',
    color: 'primary',
  },"loading":_vm.loading},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('app-form-field',{attrs:{"title":"Name"}},[_c('v-text-field',{attrs:{"rules":[
          function (v) { return !!v || 'Name erforderlich'; },
          function (v) { return (!!v && v.length <= 100) || 'Der Name ist zu lang!'; } ],"required":"","single-line":"","counter":"100"},model:{value:(_vm.team$.name),callback:function ($$v) {_vm.$set(_vm.team$, "name", $$v)},expression:"team$.name"}})],1),_c('app-form-field',{attrs:{"title":"Beschreibung"}},[_c('v-text-field',{model:{value:(_vm.team$.description),callback:function ($$v) {_vm.$set(_vm.team$, "description", $$v)},expression:"team$.description"}})],1),_c('app-data-table',{staticClass:"mx-5",attrs:{"title":_vm.table.title,"headers":_vm.table.headers,"items":_vm.team$.members,"onAdd":_vm.openEdit,"onEdit":_vm.openEdit,"onDelete":_vm.openDelete,"disableDelete":!_vm.canDelete,"filter":_vm.filter,"loading":_vm.loading},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-form',{ref:"form-add",model:{value:(_vm.table.add.valid),callback:function ($$v) {_vm.$set(_vm.table.add, "valid", $$v)},expression:"table.add.valid"}},[_c('v-combobox',{attrs:{"placeholder":"E-Mail Adressen von Teammitgliedern","prepend-icon":"email","append-icon":"","hide-details":"","single-line":"","multiple":"","chips":"","deletable-chips":"","disable-lookup":"","rules":[
              function (v) { return !!v || 'Es wird E-mail benötigt'; },
              function (v) { return /.+@.+\..+/.test(v) ||
                'Die angegebene E-mail muss gültig sein'; },
              _vm.$_validateAddEmail ],"required":"","delimiters":[' ', ',', ';']},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","disabled":!_vm.table.add.valid},on:{"click":_vm.confirmAdd}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("add")]),_vm._v("Hinzufügen ")],1)]}}])},[_c('span',[_vm._v("Mitglieder hinzufügen")])])]},proxy:true}]),model:{value:(_vm.table.add.emails),callback:function ($$v) {_vm.$set(_vm.table.add, "emails", $$v)},expression:"table.add.emails"}})],1)]},proxy:true}])}),_c('app-dialog',{attrs:{"width":"45%","title":_vm.formEditTitle,"confirmStyle":_vm.table.edit.confirmButton,"onCancel":_vm.closeEdit,"onConfirm":_vm.confirmEdit,"disableConfirm":!_vm.table.edit.valid},model:{value:(_vm.table.edit.show),callback:function ($$v) {_vm.$set(_vm.table.edit, "show", $$v)},expression:"table.edit.show"}},[_c('v-form',{ref:"form-edit",model:{value:(_vm.table.edit.valid),callback:function ($$v) {_vm.$set(_vm.table.edit, "valid", $$v)},expression:"table.edit.valid"}},[_c('v-row',{staticClass:"px-3",attrs:{"justify-center":""}},[_c('v-text-field',{staticClass:"mr-5",attrs:{"rules":_vm.emailRules,"label":"Email","placeholder":"example@mail.de","required":"","clearable":""},model:{value:(_vm.table.selectedItem.email),callback:function ($$v) {_vm.$set(_vm.table.selectedItem, "email", $$v)},expression:"table.selectedItem.email"}}),_c('v-checkbox',{attrs:{"label":"Teamleitung"},model:{value:(_vm.table.selectedItem.isAdmin),callback:function ($$v) {_vm.$set(_vm.table.selectedItem, "isAdmin", $$v)},expression:"table.selectedItem.isAdmin"}})],1)],1)],1),_c('app-dialog',{attrs:{"title":_vm.table.delete.title,"cancelStyle":_vm.table.delete.cancelButton,"confirmStyle":_vm.table.delete.confirmButton,"onCancel":_vm.closeDelete,"onConfirm":_vm.confirmDelete},model:{value:(_vm.table.delete.show),callback:function ($$v) {_vm.$set(_vm.table.delete, "show", $$v)},expression:"table.delete.show"}},[_c('span',[_vm._v(_vm._s(_vm.table.selectedItem.email))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }